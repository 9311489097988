<template>
  <v-dialog dark v-if="boxOffice" v-model="value" persistent max-width="800px">
    <v-card :style="cssVars">
      <v-card-title>{{boxOffice.name}}              
        <v-spacer/>
        <v-icon large @click="close()">mdi-close</v-icon>
      </v-card-title>
      <v-card-text>
<div id="privacypolicy">

<h2 style="font-size: 2.2rem" class="mt-10">{{boxOffice.name}} Privacy Notice</h2>
<p>Last Updated: {{utils.dateToDdMmYyString(boxOffice.privacyLastUpdated)}}</p>

<p>
Thank you for choosing to be part of our community at {{boxOffice.name}}. We are committed to protecting your personal information and your right to privacy. 
If you have any questions or concerns about our policy, or our practices with regards to your personal information, please contact us at {{boxOffice.email}}.
</p>

<p>
When you purchase tickets from us at {{makeURL()}}, or any of our event pages or apps (our Sites and Apps), you trust us with your personal information. 
We take your privacy very seriously. In this privacy notice, we describe our privacy policy. We seek to explain to you in the clearest way possible what 
information we collect, how we use it and what rights you have in relation to it.  If there are any terms in this privacy policy that you do not agree with, 
please don't buy tickets from us.
</p>

<p>
Please read this privacy policy carefully as it will help you make informed decisions about sharing your personal information with us.  
</p>


<h2>
  TABLE OF CONTENTS  
</h2>

<p>
<ol>
<li><a href="#1">What information do we collect?</a></li>
<li><a href="#2">How do we use your information?</a></li>
<li><a href="#3">Will your information be shared with anyone?</a></li>
<li><a href="#4">Who will your information be shared with?</a></li>
<li><a href="#5">Do we use cookies and other tracking technologies?</a></li>
<li><a href="#6">Is your information transferred internationally?</a></li>
<li><a href="#7">How long do we keep your information</a></li>
<li><a href="#8">How do we keep your information safe?</a></li>
<li><a href="#9">What are your privacy rights?</a></li>
<li><a href="#10">Controls for do-not-track features</a></li>
<li><a href="#11">Do we make updates to this policy?</a></li>
<li><a href="#12">How can you contact us about this policy?</a></li>
</ol>
</p>

<h2 id="1">
1. What information do we collect?
</h2>

<h3>Personal information you disclose to us</h3>

<p><b>In Short:</b> We collect personal information that you provide to us such as name, contact information and payment information.  
</p>

<p>We collect personal information that you voluntarily provide to us when buying tickets, or expressing an interest in obtaining 
  information about us or our products and services.
</p>

<p>The personal information we collect can include the following:</p>

<p><b>Name and Contact Data.</b> We collect your first and last name, email address, and other similar contact data.</p>

<p><b>Payment Data.</b> We collect data necessary to process your payment if you make purchases, such as your payment instrument number 
  (such as a credit card number), and the security code associated with your payment instrument. All payment data is stored by our payment processor 
  (Stripe) and you should review its privacy policies and contact the payment processor directly to respond to your questions. </p<>

<p>All personal information that you provide to us must be true, complete and accurate, and you must notify us of any changes to such personal information.  </p>


<h2 id="2">2. How do we use your information?  </h2>

<p><b>In Short:</b> We process your information for purposes based on legitimate business interests, the fulfillment of our contract with you, compliance 
with our legal obligations, and/or your consent.  </p>

<p>We use personal information collected via our Sites or Apps for a variety of business purposes described below. We process your personal information 
  for these purposes in reliance on our legitimate business interests ("Business Purposes"), in order to enter into or perform a contract with 
  you ("Contractual"), with your consent ("Consent"), and/or for compliance with our legal obligations ("Legal Reasons"). We indicate the specific 
  processing grounds we rely on next to each purpose listed below.  </p>

<p>We use the information we collect or receive:  </p>

<p><ul>

<li><b>To send administrative information to you.</b> We may use your personal information to send you product, service and new feature information and/or 
  information about changes to our terms, conditions, and policies.         </li>

<li><b>Request Feedback.</b> We may use your information to request feedback and to contact you.  </li>

<li><b>To enforce our terms, conditions and policies.</b></li>

<li><b>To respond to legal requests and prevent harm.</b> If we receive a subpoena or other legal request, we may need to inspect the data we hold to determine 
  how to respond.  </li>

<li><b>For other Business Purposes.</b> We may use your information for other Business Purposes, such as data analysis, identifying usage trends, 
  determining the effectiveness of our promotional campaigns and to evaluate and improve our Sites or Apps, products, services, marketing and your 
  experience.</li> 
</ul></p>

<h2 id="3">3. Will your information be shared with anyone?  </h2>

<p><b>In Short:</b> We only share information with your consent, to comply with laws, to protect your rights, or to fulfill business obligations.   </p>

<p>We may process or share data based on the following legal basis:</p>

<p><ul>
<li><b>Consent:</b> We may process your data if you have given us specific consent to use your personal information in a specific purpose. </li>

<li><b>Legitimate Interests:</b> We may process your data when it is reasonably necessary to achieve our legitimate business interests. </li>

<li><b>Performance of a Contract:</b> Where we have entered into a contract with you, we may process your personal information to fulfill the terms of our 
contract. </li>

<li><b>Legal Obligations:</b> We may disclose your information where we are legally required to do so in order to comply with applicable law, governmental 
requests, a judicial proceeding, court order, or legal process, such as in response to a court order or a subpoena (including in response to public authorities 
to meet national security or law enforcement requirements). </li>

<li><b>Vital Interests:</b> We may disclose your information where we believe it is necessary to investigate, prevent, or take action regarding potential 
violations of our policies, suspected fraud, situations involving potential threats to the safety of any person and illegal activities, or as evidence in 
litigation in which we are involved.</li>
</ul></p>

<p>More specifically, we may need to process your data or share your personal information in the following situations:   </p>

<p><ul>
<li><b>Vendors, Consultants and Other Third-Party Service Providers.</b> We may share your data with third party vendors, service providers, contractors or 
agents who perform services for us or on our behalf and require access to such information to do that work. Examples include: payment processing, 
data analysis, email delivery, hosting services, customer service and marketing efforts. We may allow selected third parties to use tracking technology 
on the Sites or Apps, which will enable them to collect data about how you interact with the Sites or Apps over time. This information may be used to, 
among other things, analyze and track data, determine the popularity of certain content and better understand online activity. Unless described in this 
Policy, we do not share, sell, rent or trade any of your information with third parties for their promotional purposes.  </li>

<li><b>Business Transfers.</b> We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, 
financing, or acquisition of all or a portion of our business to another company.           </li>
</ul></p>

<h2 id="4">4. Who will your information be shared with?</h2>

<p><b>In Short:</b>  We only share information with the following third parties.  </p>
  
<p>We only share and disclose your information with the following third parties. We have categorized each party so that you may be easily understand the 
  purpose of our data collection and processing practices. If we have processed your data based on your consent and you wish to revoke your consent, 
  please contact us.  </p>

<p><ul>
<li><b>Advertising, Direct Marketing, and Lead Generation</b><br/>
Mailchimp
</li>
<li><b>Communicate and Chat with Users</b><br/>
Sendgrid
</li>
<li><b>Invoice and Billing</b><br/>
Stripe
</li>
<li><b>Web and Mobile Analytics</b><br/>
Google Analytics
</li>
</ul></p>

<h2 id="5">5. Do we use cookies and other tracking technologies?</h2>
<p><b>In Short:</b> We may use cookies and other tracking technologies to collect and store your information.  </p>

<p>We may use cookies and similar tracking technologies (like web beacons and pixels) to access or store information. Specific information about how we use 
  such technologies and how you can refuse certain cookies is set out in Happy Ticket's <a href="/cookies">Cookie Policy</a>.

<h2 id="6">6. Is your information transferred internationally?</h2>

<p><b>In Short:</b> We may transfer, store, and process your information in countries other than your own.  </p>

<p>Our servers are located in United States. If you are buying tickets from outside United States, please be aware that your information may be 
  transferred to, stored, and processed by us in our facilities and by those third parties with whom we may share your personal information (see 
  "Will your information be shared with anyone?" above), in United States, and other countries. </p>

<p>If you are a resident in the European Economic Area, then these countries may not have data protection or other laws as comprehensive as those in your 
  country. We will however take all necessary measures to protect your personal information in accordance with this privacy policy and applicable law. </p>

<h3>EU-U.S. Privacy Shield Framework:  </h3>

<p>In particular {{boxOffice.name}} complies with the EU-U.S. Privacy Shield Framework as set forth by the U.S. Department of Commerce regarding the collection, 
  use, and retention of personal information transferred from the European Union to the United States and has certified its compliance with it. As such, 
  {{boxOffice.name}} is committed to subjecting all personal information received from European Union (EU) member countries, in reliance on the Privacy 
  Shield Framework, to the Framework’s applicable Principles. To learn more about the Privacy Shield Framework, visit the U.S. Department of Commerce’s 
  Privacy Shield List. </p>

<p>{{boxOffice.name}} is responsible for the processing of personal information it receives, under the Privacy Shield Framework, and subsequently transfers 
  to a third party acting as an agent on its behalf. </p>

<p>With respect to personal information received or transferred pursuant to the Privacy Shield Framework, {{boxOffice.name}} is subject to the regulatory 
  enforcement powers of the U.S. FTC. In certain situations, we may be required to disclose personal information in response to lawful requests by public 
  authorities, including to meet national security or law enforcement requirements.    </p>


<h2 id="7">7. How long do we keep your information? </h2>

<p><b>In Short:</b> We keep your information for as long as necessary to fulfill the purposes outlined in this privacy policy unless otherwise required by 
law.  </p>

<p>We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy policy, unless a longer retention 
  period is required or permitted by law (such as tax, accounting or other legal requirements). No purpose in this policy will require us keeping your 
  personal information for longer than 1 year past the termination of the user's account.</p>

<p>When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymize it, or, if this is not possible 
  (for example, because your personal information has been stored in backup archives), then we will securely store your personal information and isolate 
  it from any further processing until deletion is possible.</p>



<h2 id="8">8. How do we keeo your information safe?</h2>

<p><b>In Short:</b> We aim to protect your personal information through a system of organisational and technical security measures. </p>

<p>We have implemented appropriate technical and organisational security measures designed to protect the security of any personal information we process. 
  However, please also remember that we cannot guarantee that the internet itself is 100% secure. Although we will do our best to protect your personal 
  information, transmission of personal information to and from our Sites or Apps is at your own risk. You should only access the services within a 
  secure environment. </p>

<h2 id="9">9. What are your privacy rights? </h2>

<p><b>In Short:</b> In some regions, such as the European Economic Area, you have rights that allow you greater access to and control over your personal 
information. You may review, change, or terminate your account at any time.</p>

<p>In some regions (like the European Economic Area), you have certain rights under applicable data protection laws. These may include the right (i) to 
  request access and obtain a copy of your personal information, (ii) to request rectification or erasure; (iii) to restrict the processing of your personal 
  information; and (iv) if applicable, to data portability. In certain circumstances, you may also have the right to object to the processing of your 
  personal information. To make such a request, please use the contact details provided below. We will consider and act upon any request in accordance 
  with applicable data protection laws. </p>

<p>If we are relying on your consent to process your personal information, you have the right to withdraw your consent at any time. Please note however 
  that this will not affect the lawfulness of the processing before its withdrawal.</p>

<p>If you are resident in the European Economic Area and you believe we are unlawfully processing your personal information, you also have the right to 
  complain to your local data protection supervisory authority. You can find their contact details here: 
  http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm </p>
 

<h3>Account Information </h3>

<p><b>Cookies and similar technologies:</b>  Most Web browsers are set to accept cookies by default. If you prefer, you can usually choose to set your 
browser to remove cookies and to reject cookies. If you choose to remove cookies or reject cookies, this could affect certain features or services of our 
Sites or Apps. For further information, please see Happy Ticket's <a href="/cookies">Cookie Policy</a>.  </p>

<p><b>Opting out of email marketing:</b> You can unsubscribe from our marketing email list at any time by clicking on the unsubscribe link in the emails 
that we send or by contacting us using the details provided below. You will then be removed from the marketing email list. </p>

<h2 id="10">10. Controls for do-not-track features</h2>

<p>Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track (“DNT”) feature or setting you can activate to signal 
  your privacy preference not to have data about your online browsing activities monitored and collected. No uniform technology standard for recognizing 
  and implementing DNT signals has been finalized. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically 
  communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you 
  about that practice in a revised version of this Privacy Policy. </p>



<h2 id="11">11. Do we make updates to this policy?</h2>

<p><b>In Short:</b> Yes, we will update this policy as necessary to stay compliant with relevant laws.</p>

<p>We may update this privacy policy from time to time. The updated version will be indicated by an updated “Revised” date and the updated version will be 
  effective as soon as it is accessible. If we make material changes to this privacy policy, we may notify you either by prominently posting a notice of such 
  changes or by directly sending you a notification. We encourage you to review this privacy policy frequently to be informed of how we are protecting your 
  information. </p>


<h2 id="12">12. How can you contact us about this policy?</h2>

<p>If you have questions or comments about this policy, you may contact our Data Protection Officer (DPO), at {{boxOffice.email}} 
  or by post to:

<p>
{{boxOffice.name}}<br/>
{{boxOffice.address}}
</p>

</div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
  
<script>
  import Utils from '@/services/Utils.js'
  export default {

    props: {
      value: null,
      boxOffice: {},
    },

    computed: {
      
      utils: function () {
        return Utils;
      },
      cssVars: function () {
        if (this.boxOffice) {
          return {
            '--text-color': this.boxOffice.textColour,
            '--background-color': this.boxOffice.backgroundColour
          }
        }
      },

    },

    methods: {
      makeURL() {
        var url = window.location.origin + "/boxOffice/" + this.boxOffice.path;
        return url;
      },

      close() {
        this.$emit('input', false);
      },
    },

  }
</script>

<style lang="sass" scoped>
  #privacypolicy
    p
      margin-top:20px
    h3
      margin-top:40px
    h2
      margin-top:60px
    a
      color: #ffffff
  .v-sheet.v-card
    border: thick solid
    border-radius: 0px
  .v-dialog
    border-radius: 0px
</style>
